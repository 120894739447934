import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20:00 to establish a max of:`}</p>
    <p>{`1-Power Clean +\\
2-Hang Squat Cleans`}</p>
    <p>{`then,`}</p>
    <p>{`5 rounds for time:`}</p>
    <p>{`6-Power Cleans (155/105)(Rx+ 185/125)`}</p>
    <p>{`200m Run`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      